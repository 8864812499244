<!--
 * @Author: huyd
 * @Date: 2023-12-26 15:57:04
 * @LastEditors: huyd
 * @LastEditTime: 2023-12-29 15:47:00
 * @FilePath: \wavelink-portal\src\App.vue
 * @Description: 
-->
<template>
  <div id="app">
    <header class="header">
      <img alt="logo" src="./assets/images/logo.png" />
      <!-- <nav>
        <router-link to="/" class="route-item">HOME</router-link>
        <router-link to="/" class="route-item">SOLUTIONS</router-link>
        <router-link to="/" class="route-item">ABOUT US</router-link>
      </nav> -->
    </header>
    <router-view />
  </div>
</template>

<script>
import DevicePixelRatio from "./utils/window_reset.js";
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    new DevicePixelRatio().init();
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.header {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 5;
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 56px 82px 0;
  transform: translateX(-50%);
  box-sizing: border-box;
}
.route-item {
  color: #fff;
  & + .route-item {
    margin-left: 67px;
  }
}
</style>
