<template>
  <div class="home">
    <el-carousel class="home-banner" :height="carouselHeight" :autoplay="true">
      <el-carousel-item v-for="item in 3" :key="item">
        <img
          class="w-percent-100"
          src="../assets/images/banners/banner.png"
          alt=""
        />
        <div class="banner-text">
          <div class="text-bold">Wavelink Safari</div>
          <div class="subtitle-text">
            Provide 7x24-hour satellite broadband Internet service
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="statinary-solution relative">
      <img class="w-percent-100" src="../assets/images/bgg.png" alt="" />
      <div class="statinary-solution-content">
        <div class="module-title">
          <div class="title-text white">Statinary Solution</div>
          <p class="subtitle">Make every jouney fun!</p>
          <div class="title-border-bottom white"></div>
        </div>
        <ul class="color-white">
          <li
            class="intro-item"
            v-for="(item, index) in listData2"
            :key="index"
          >
            <div class="intro-item-title">
              {{ index + 1 }}. {{ item.title }}
            </div>
            <div class="intro color-white">
              {{ item.intro }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="mobile-solution flex items-center">
      <div class="product-images">
        <el-carousel height="685px" :autoplay="true" arrow="never">
          <el-carousel-item v-for="(item, index) in productImages" :key="index">
            <img class="product-item-image" :src="item" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="flex-1">
        <div class="module-title">
          <div class="title-text">Land Mobile Solution</div>
          <p class="subtitle">Non-stop high-speed internet!</p>
          <div class="title-border-bottom"></div>
        </div>
        <ul>
          <li
            class="intro-item"
            v-for="(item, index) in listData1"
            :key="index"
          >
            <div class="intro-item-title">
              {{ index + 1 }}. {{ item.title }}
            </div>
            <div class="intro">
              {{ item.intro }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="maritime-solution flex items-center justify-center">
      <div class="flex-1">
        <div class="module-title">
          <div class="title-text white">Maritime Solution</div>
          <p class="subtitle">High-speed internet on the Sea!</p>
          <div class="title-border-bottom white"></div>
        </div>
        <ul class="color-white">
          <li
            class="intro-item"
            v-for="(item, index) in listData4"
            :key="index"
          >
            <div class="intro-item-title">
              {{ index + 1 }}. {{ item.title }}
            </div>
            <div class="intro color-white">
              {{ item.intro }}
            </div>
          </li>
        </ul>
      </div>
      <div class="flex items-center ml-90">
        <img src="../assets/images/product/sea1.png" alt="" />
        <div class="ml-45 flex-col">
          <img class="mb-45" src="../assets/images/product/sea2.png" alt="" />
          <img class="radar" src="../assets/images/product/sea3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mobile-management">
      <div class="flex items-center mobile-management-top">
        <img
          class="mobile-image"
          src="../assets/images/mobile/mobile.png"
          alt=""
        />
        <div class="flex-1">
          <div class="module-title">
            <div class="title-text">Mobile System Management Platform</div>
          </div>
          <ul>
            <li
              class="intro-item"
              v-for="(item, index) in listData3"
              :key="index"
            >
              <div class="intro">
                <div
                  class="intro_text"
                  v-for="text in item"
                  :key="text + index"
                >
                  {{ text }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <ul class="flex items-center">
        <li class="feature-item bg-blue flex items-center justify-between">
          <div class="text-24 color-white">FAST</div>
          <div class="flex justify-end">
            <div class="icon-box flex items-center justify-center">
              <img src="../assets/images/mobile/icon1.png" alt="" />
            </div>
          </div>
          <!-- <div class="color-white">
            <div class="text-24 mb-10">HIGH-SPEED</div>
            <div class="text-14">
              Wavelink Safari Pte Ltd. offers high-speed internet almost
              anywhere Asia&Africa
            </div>
          </div> -->
        </li>
        <li class="feature-item bg-red flex items-center justify-between">
          <div class="text-24 color-white">EASY</div>
          <div class="flex justify-end">
            <div class="icon-box flex items-center justify-center">
              <img src="../assets/images/mobile/icon2.png" alt="" />
            </div>
          </div>
          <!-- <div class="color-white">
            <div class="text-24 mb-10">EASY TO GET ONLINE</div>
            <div class="text-14">
              The terminal can install on the luggage rack without any
              modification. Ready to use upon startup
            </div>
          </div> -->
        </li>
        <li class="feature-item bg-white flex items-center justify-between">
          <div class="text-24 color-black">EFFICIENT</div>
          <div class="flex justify-end">
            <div class="icon-box flex items-center justify-center">
              <img src="../assets/images/mobile/icon3.png" alt="" />
            </div>
          </div>
          <!-- <div class="color-white">
            <div class="text-24 mb-10 color-black">LOW COST SOUTION</div>
            <div class="text-14 color-gray">
              Wavelink provide affordable internet access solutions based on
              HTS(high throughput) satellite
            </div>
          </div> -->
        </li>
      </ul>
    </div>

    <div class="about-us relative text-center">
      <div
        class="relative contact-btn color-white flex items-center"
        @click="showContact"
      >
        <div class="text-center flex-1">Contact Sales</div>
        <img class="contact-icon" src="../assets/images/arrow.png" alt="" />
      </div>
      <div class="text-bold text-40">About Us</div>
      <div class="about-us-content color-gray">
        <p class="mb-10">
          Whether you’re a full-time RVer, a weekend warrior, or just someone
          who enjoys the great outdoors, staying connected is likely high on
          your priority list.
        </p>
        <p class="mb-10">
          There are several ways to stay connected while living or traveling in
          an RV. Choices range from using your mobile phone’s hotspot, dedicated
          Wi-Fi devices, or even setting up a complete satellite internet
          system.
        </p>
        <p class="mb-10">
          The best choice will depend on your specific needs, such as how often
          you need to be online and the areas you plan to travel to. Wavelink
          Safari Pte. Ltd. is the right one who provide 7x24 non-stop satellite
          broadband internet service.
        </p>
        <p class="mb-10">
          Wavelink Safari Pte. Ltd. is a Singapore based company, we use
          HTS(high throughput satellite) to provide low cost broadband service
          to travelers around Asia&Africa.
        </p>
        <p class="mb-10">We provide services through membership system.</p>
      </div>
      <img
        class="w-percent-100"
        src="../assets/images/about-image.png"
        alt=""
      />
    </div>

    <div class="footer flex-col justify-between">
      <ul class="flex w-percent-100">
        <!-- <li class="footer-item">
          <div class="footer-item-title">SOLUTIONS</div>
          <div class="mb-20">Land Mobile Solution</div>
          <div class="mb-20">Maritime solution</div>
          <div class="mb-20">Efhcient Intelligent Management Platform</div>
          <div class="mb-20">Statinary Solution</div>
        </li>
        <li class="footer-item">
          <div class="footer-item-title">ABOUT US</div>
          <div class="mb-20">Company Profile</div>
          <div class="mb-20">Company CultureDevelopment Path</div>
          <div class="mb-20">Company Emvironment</div>
          <div class="mb-20">Honor</div>
        </li>
        <li class="footer-item flex-col justify-between" style="width: 312px">
          <div class="footer-item-title">
            <img class="mb-10" src="../assets/images/footer/phone.png" alt="" />
            <div>+86-755-23405959</div>
          </div>
          <div class="footer-item-title">
            <img
              class="mb-10"
              src="../assets/images/footer/location.png"
              alt=""
            />
            <div>
              Room 501, Building 1, No. 51.Gezhen East Road, PinghuCommunity,
              Pinghu Street.Longgang District, shenzhen
            </div>
          </div>
        </li>
        <li class="footer-item">
          <div class="footer-item-title">LINKS</div>
          <div class="alike-input">
            <span class="text-14">Google</span>
          </div>

          <ul class="flex items-center justify-between">
            <li>
              <img src="../assets/images/footer/f.png" alt="" />
            </li>
            <li>
              <img src="../assets/images/footer/i.png" alt="" />
            </li>
            <li>
              <img src="../assets/images/footer/t.png" alt="" />
            </li>
            <li>
              <img src="../assets/images/footer/y.png" alt="" />
            </li>
          </ul>
        </li> -->
        <li class="">
          <div class="flex items-center">
            <img class="" src="../assets/images/footer/phone.png" alt="" />
            <div class="ml-15">+65 9039 1384</div>
          </div>
        </li>
        <li class="" style="margin-left: 135px">
          <div class="flex items-center">
            <img class="" src="../assets/images/footer/location.png" alt="" />
            <div class="ml-15">
              60 PAYA LEBAR ROAD,#07-54 ,PAYA LEBAR SQUARE,SINGAPORE
            </div>
          </div>
        </li>
        <li class="" style="margin-left: 135px">
          <div class="flex items-center">
            <img class="" src="../assets/images/footer/email.png" alt="" />
            <div class="ml-15">customer@wavelink-safari.com</div>
          </div>
        </li>
      </ul>
      <div class="text-center">
        © Corpright 2024 WAVELINK SAFARI PTE.LTD. All rights reserved
      </div>
    </div>
    <!-- <el-dialog
      title="CONTACT SALES"
      :visible.sync="dialogFormVisible"
      custom-class="email-dialog"
      @close="close()"
    >
      <div class="color-999 mb-30">
        Our sales team is on hand to help you with all enquiries. Please
        complete and submit the form below and we will be in touch. Thank you!
      </div>
      <div class="p-20">
        <el-form
          ref="emailForm"
          :model="form"
          :label-width="formLabelWidth"
          :rules="rules"
          :label-position="'top'"
        >
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              placeholder="Name"
            ></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="form.email"
              autocomplete="off"
              placeholder="Email"
            ></el-input>
          </el-form-item>
          <el-form-item label="Phone No." prop="phoneNo">
            <el-input
              v-model="form.phoneNo"
              autocomplete="off"
              placeholder="Phone No."
            ></el-input>
          </el-form-item>
          <el-form-item label="Message" prop="message">
            <el-input
              v-model="form.message"
              type="textarea"
              autocomplete="off"
              placeholder="Message"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <div
          class="relative contact-btn color-white flex items-center form-btn"
          @click="sendEmail"
        >
          <div class="text-center flex-1">Send Message</div>
          <img class="contact-icon" src="../assets/images/arrow.png" alt="" />
        </div>
      </div>
    </el-dialog> -->
    <el-dialog
      title="Contact Sales"
      custom-class="email-dialog"
      :visible.sync="dialogVisible"
    >
      <div class="dialog-inner">
        <div class="tip">
          Our sales team is on hand to help you with all enquiries.
        </div>
        <div class="label">
          <img src="@/assets/images/dialog/email.png" />
          <div>Email</div>
        </div>
        <div class="value">customer@wavelink-safari.com</div>
        <div class="label">
          <img src="@/assets/images/dialog/phone.png" />
          <div>Phone No.</div>
        </div>
        <div class="value">+65 9039 1384</div>
        <div class="label">
          <img src="@/assets/images/dialog/whatsApp.png" />
          <div>WhatsApp</div>
        </div>
        <div class="qrcode">
          <div class="code-img">
            <img src="@/assets/images/dialog/qrcode.png" alt="" />
          </div>
          <div class="app-name">Wavelink-Safari</div>
          <div class="app-tip">WhatsApp contact</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import emailjs from "@emailjs/browser";
// import { emailConfig } from "@/config";
import axios from "axios";
import qs from "qs";
import { EMAIL_BACK_URL } from "@/config";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      carouselHeight: "1080px",
      listData1: [
        {
          title: "SEAMLESS SATELLITE COVERAGE",
          intro:
            "Wavelink Safari Pte. Ltd. offers high-speed internet almost anywhere across Asia&Africa. Including every corner of wild islands and mountainous areas.",
        },
        {
          title: "DESIGNED FOR HARSH ENVIRONMENTS",
          intro:
            "The terminal can withstand extreme cold, heat, sleet, heavy rain, and hurricane winds.",
        },
        {
          title: "EASY TO GET ONLINE",
          intro:
            "The terminal can install on the luggage rack without any modification. Ready to use upon startup.",
        },
        {
          title: "LOW COST SOUTION",
          intro:
            "Wavelink Safari Pte. Ltd. provide affordable internet access solutions based on HTS(high throughput) satellite.",
        },
      ],
      productImages: [
        require("@/assets/images/product/1.png"),
        require("@/assets/images/product/2.png"),
        require("@/assets/images/product/3.png"),
      ],
      listData4: [
        {
          title: "HIGH-SPEED INTERNET SURFING",
          intro:
            "Wavelink Safari Pte. Ltd. delivers high-speed, low-latency internet both in-motion and while anchored. Enjoy streaming movies, making video calls, playing video games, and more - all while out on the water.",
        },
        {
          title: "DESIGNED FOR HARSH ENVIRONMENTS",
          intro:
            "The terminal can withstand extreme conditions while at sea, including cold, heat, sleet, heavy rain, and hurricane winds.",
        },
        {
          title: "EASY INTERNET ACCESS",
          intro:
            "Integrate with existing onboard networks via direct Ethernet connection or use the included Wi-Fi router，very easy internet access.",
        },
        {
          title: "LOW COST SERVICE",
          intro:
            "Wavelink Safari Pte. Ltd. provide affordable internet access solutions based on HTS(high throughput) satellite.",
        },
      ],
      listData3: [
        [
          "1.  Quick and simple setup",
          "AR Star Finder, Intelligent Star Alignment",
        ],
        [
          "2.  Simple and secure payments ",
          "Your payment details are encrypted and stored for quick and easy payments and purchases",
        ],
        [
          "3.  Proactive plan management",
          "Avoid “bill-shock” with on-the-go access to your account details and regular alerts on your remaining plan credit and data allocation",
        ],
        [
          "4.  Convenient plan renewal",
          "Get on with life as you keep your service up and running with plans that renew automatically",
        ],
        [
          "5.  Easy top-up",
          "Boost your data allowance when you need it or have us do it automatically for you",
        ],
      ],
      listData2: [
        {
          title: "SEAMLESS SATELLITE COVERAGE",
          intro:
            "Wavelink Safari Pte. Ltd. offers high-speed internet almost anywhere across Asia&Africa, Including every corner of wild islands and mountainous areas.",
        },
        {
          title: "EASY OPERATION DESIGN",
          intro:
            "The terminal connects within minutes and packs up quickly when it's time to move to your next destination.",
        },
        {
          title: "DESIGNED FOR HARSH ENVIRONMENTS",
          intro:
            "The terminal is designed to endure the elements - it can melt snow and withstand sleet, heavy rain, and harsh winds.",
        },
        {
          title: "LOW COST SERVICE",
          intro:
            "Wavelink Safari Pte. Ltd. provide affordable internet access solutions based on HTS(high throughput) satellite.",
        },
      ],
      dialogVisible: false,
      form: {
        name: "",
        email: "",
        phoneNo: "",
        message: "",
      },
      formLabelWidth: "90px",
      rules: {
        name: [
          {
            required: true,
            message: "Please enter",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Please enter a valid email address",
            trigger: ["blur", "change"],
          },
        ],
        phoneNo: [
          {
            required: true,
            message: "Please enter",
            trigger: ["blur", "change"],
          },
        ],
        message: [
          {
            required: true,
            message: "Please enter",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      const width = window.innerWidth;
      const resHeight = (width * 1080) / 1920;
      this.carouselHeight = `${resHeight}px`;
      console.log(resHeight);
    },
    showContact() {
      this.dialogVisible = true;
    },
    // 发送邮件
    sendEmail() {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          axios.defaults.headers["Content-Type"] =
            "application/x-www-form-urlencoded";
          console.log(EMAIL_BACK_URL);
          axios.post(EMAIL_BACK_URL, qs.stringify(this.form)).then((res) => {
            if (res.data.code == 200) {
              this.$notify({
                title: "成功",
                message: res.data.msg,
                type: "success",
              });
              this.close();
            } else {
              this.$notify({
                title: "提示",
                message: "发送失败",
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$refs.emailForm.resetFields();
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  max-width: 1920px;
  margin: 0 auto;
}
.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 72px;
  .subtitle-text {
    margin-top: 18px;
    font-size: 18px;
  }
}
.module-title {
  margin-bottom: 50px;
  color: #000000;
}
.title-border-bottom {
  width: 50px;
  height: 4px;
  background: #000000;
  border-radius: 2px;
  &.white {
    background: #fff;
  }
}
.title-text {
  font-size: 30px;
  color: #000000;
  margin-bottom: 10px;
  font-family: "D-DIN";
  &.white {
    color: #fff;
  }
}
.subtitle {
  color: #ff5758;
  margin-bottom: 18px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-45 {
  margin-left: 45px;
}
.mb-45 {
  margin-bottom: 45px;
}
.radar {
  width: 166px;
}
.intro-item {
  margin-bottom: 20px;
}
.intro-item-title {
  margin-bottom: 10px;
}
.intro {
  font-size: 14px;
  color: #71707c;
  &.color-white {
    color: #fff;
  }
  .intro_text {
    margin-bottom: 3px;
  }
}
.mobile-solution {
  padding: 122px 80px;
  .product-images {
    width: 840px;
    margin-right: 56px;
    position: relative;
  }
}
.maritime-solution {
  padding: 133px 80px;
  background: url("../assets/images/product/bg1.png") no-repeat top left / 100%
    100%;
}
.bg-blue {
  background-color: #11253a;
}
.bg-red {
  background-color: #ff5758;
}
.bg-white {
  background-color: #fff;
}
.mobile-management {
  padding: 155px 160px;
  background: url("../assets/images/mobile/bg.png") no-repeat top left / 100%;
  .mobile-management-top {
    margin-bottom: 54px;
  }
  .mobile-image {
    margin-right: 177px;
  }
  .feature-item {
    flex: 1;
    width: 505px;
    // height: 135px;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08),
      0px 12px 24px 0px rgba(17, 37, 58, 0.1);
    border-radius: 8px;
    & + .feature-item {
      margin-left: 42px;
    }
  }
  .icon-box {
    width: 64px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
}
.statinary-solution {
  .statinary-solution-content {
    position: absolute;
    top: 50%;
    right: 127px;
    transform: translateY(-50%);
    width: 700px;
  }
}
.about-us {
  padding: 45px 160px 0;
  .about-us-content {
    margin: 36px 220px 75px;
  }
}
.contact-btn {
  width: 337px;
  padding: 24px 30px;
  background: #ff5758;
  border-radius: 30px;
  margin: 0 auto 200px;
  cursor: pointer;
  &.form-btn {
    width: 240px;
    margin-bottom: 0;
  }
}
.footer {
  width: 100%;
  height: 195px;
  background: #303942;
  padding: 56px 160px 37px;
  color: #fff;
  font-size: 12px;
  .footer-item-title {
    margin-bottom: 33px;
    font-size: 16px;
  }
  .alike-input {
    width: 244px;
    height: 40px;
    line-height: 40px;
    padding-left: 50px;
    background: #434d59;
    border-radius: 20px;
    margin-bottom: 24px;
  }
}
// 对小屏临时做一些处理
@media (min-width: 180px) and (max-width: 900px) {
  .maritime-solution,
  .about-us {
    padding: 20px;
    .about-us-content {
      margin: 0;
    }
  }
  .maritime-solution img {
    width: 100%;
  }
  .w-percent-100 {
    height: 100%;
  }
  .mobile-management {
    padding: 20px;
    padding-left: 0;
    padding-right: 0;
    img {
      width: 50%;
    }
    .mobile-image {
      margin-right: 20px;
    }

    .text-24 {
      font-size: 12px;
    }

    .items-center {
      justify-content: space-around;
    }

    .feature-item {
      width: 30%;
      flex: none;
      padding: 15px;
      img {
        width: 100%;
      }
      & + .feature-item {
        margin-left: 0;
      }
    }
    .icon-box {
      width: 24px;
      height: 24px;
    }
  }
  .product-images img {
    width: 100%;
  }

  .contact-btn {
    margin: 0 auto 30px;
  }

  .footer {
    padding: 10px;
    height: auto;
    li {
      margin-left: 0 !important;
      margin-bottom: 10px;
    }
    .w-percent-100 {
      display: block;
    }
  }
}
</style>
<style lang="scss">
.home-banner {
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 1;
  }
  .is-active .el-carousel__button {
    background: #ff5758;
  }
}
.mobile-solution {
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 1;
    background: #ff5758;
  }
  .is-active .el-carousel__button {
    width: 10px;
    height: 10px;
    background: #353448;
  }
  .el-carousel {
    position: initial;
  }
  .el-carousel__indicators {
    bottom: -34px;
    left: 35%;
  }
}
.email-dialog {
  width: 459px;
  height: 531px;
  font-family: PingFangSC, PingFang SC;
  background-image: url("~@/assets/images/banners/dialog-banner.png");
  .el-dialog__header {
    border-bottom: 1px solid #ddd;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .el-dialog__body {
    padding: 33px 40px 0 40px;
  }
  .dialog-inner {
    .tip {
      font-size: 14px;
      // text-align: center;
      margin-bottom: 44px;
      font-weight: 500;
      color: #999999;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #000000;
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
    .value {
      height: 28px;
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: 400;
      color: #666666;
    }
    .qrcode {
      .code-img {
        padding-left: 14px;
      }
      img {
        width: 76px;
        height: 76px;
      }
      .app-name {
        font-size: 12px;
        padding-left: 5px;
        margin-top: 7px;
        font-weight: 600;
        color: #000000;
      }
      .app-tip {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
